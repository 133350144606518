var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.download ? _c('div', {
    staticClass: "board-container"
  }, [_c('table', {
    staticClass: "board-view"
  }, [_c('tr', [_c('th', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.download.subject))])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('common.작성일')) + " : " + _vm._s(_vm.$dayjs(_vm.download.createdAt).format("YYYY-MM-DD HH:mm:ss")))])]), _c('v-spacer'), _vm.download.upload ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "mr-3"
  }, [_vm._v(" " + _vm._s(_vm.download.upload.split('/').pop()))]), _c('v-btn', {
    staticClass: "v-size--xx-small",
    attrs: {
      "href": _vm.download.upload,
      "download": _vm.download.upload.split('/').pop(),
      "icon": ""
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/icon/icon-download.png"
    }
  })], 1)], 1) : _vm._e()], 1)], 1)]), _c('tr', [_c('td', {
    staticClass: "content_box",
    attrs: {
      "colspan": "2"
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.download.content.replace(/\n/g, '<br>'))
    }
  })])])]), _c('table', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "board-view-navi"
  }, [_vm._m(0), _vm._m(1)]), _c('div', {
    staticClass: "mt-20px mt-md-30px"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "v-btn--group justify-end"
  }, [_c('v-btn', {
    attrs: {
      "large": "",
      "color": "primary"
    }
  }, [_vm._v("수정")]), _c('v-btn', {
    attrs: {
      "large": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("삭제")]), _c('v-btn', {
    staticClass: "grey-2--text",
    attrs: {
      "large": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("답글")])], 1), _c('div', {
    staticClass: "v-btn--group d-flex justify-end"
  }, [_c('v-btn', {
    attrs: {
      "large": "",
      "outlined": "",
      "tile": "",
      "color": "grey lighten-3"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v(_vm._s(_vm.$t("common.list")))])])], 1)])]) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("이전글")]), _c('td', [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("이전 게시글 제목입니다.")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("다음글")]), _c('td', [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("다음 게시글 제목입니다.")])])]);

}]

export { render, staticRenderFns }