<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="고객센터" tabActive="서식 다운로드" bg="/images/center/visual.jpg">
                <template #title>고객센터</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <template #tit>서식 다운로드</template>
            
            <download-list v-if="!$route.params._download" />
            <download-view v-else />
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import DownloadList from "@/components/client/center/downloads/download-list.vue";
import DownloadView from "@/components/client/center/downloads/download-view.vue";

export default {
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        DownloadList,
        DownloadView,
    },
};
</script>
