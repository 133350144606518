import { render, staticRenderFns } from "./u-page-section-basic.vue?vue&type=template&id=026c439c&scoped=true&"
import script from "./u-page-section-basic.vue?vue&type=script&lang=js&"
export * from "./u-page-section-basic.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "026c439c",
  null
  
)

export default component.exports