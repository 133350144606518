<template>
    <div v-if="download" class="board-container">
        <table class="board-view">
            <tr>
                <th colspan="2">{{ download.subject }}</th>
            </tr>
            <tr>
                <td colspan="2">
                    <!-- <span>작성자 : 관리자</span> &nbsp;&nbsp;&nbsp; -->
                    <v-row class="row--small" justify="space-between">
                        <v-col cols="auto">
                            <span>{{$t('common.작성일')}} : {{ $dayjs(download.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</span>
                        </v-col>
                        <v-spacer/>
                        <v-col v-if="download.upload" cols="auto">
                            <span class="mr-3"> {{ download.upload.split('/').pop() }}</span>
                            <v-btn :href="download.upload" :download="download.upload.split('/').pop()" icon class="v-size--xx-small"><v-img src="/images/icon/icon-download.png"></v-img></v-btn>
                        </v-col>
                    </v-row>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="content_box">
                    <div v-html="download.content.replace(/\n/g,'<br>')"></div>
                </td>
            </tr>
        </table>

        <table v-show="false" class="board-view-navi">
            <tr>
                <th width="20%">이전글</th>
                <td><a href="">이전 게시글 제목입니다.</a></td>
            </tr>
            <tr>
                <th width="20%">다음글</th>
                <td><a href="">다음 게시글 제목입니다.</a></td>
            </tr>
        </table>

        <div class="mt-20px mt-md-30px">
            <div v-show="false" class="v-btn--group justify-end">
                <v-btn large color="primary">수정</v-btn>
                <v-btn large outlined color="primary">삭제</v-btn>
                <v-btn large outlined color="primary" class="grey-2--text">답글</v-btn>
            </div>
            <div class="v-btn--group d-flex justify-end">
                <v-btn @click="$router.go(-1)" large outlined tile color="grey lighten-3">
                    <span class="grey--text">{{ $t("common.list") }}</span>
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api";

export default {
    components: {},
    data() {
        return {
            download: null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                var { download } = await api.v1.center.downloads.get({ _id: this.$route.params._download });
                // if (download.upload) download.upload = await api.getResource(download.upload);
                this.download = download
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>
