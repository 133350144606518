var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "board-search-wrap"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-select', {
    staticClass: "search-select v-input--density-compact",
    attrs: {
      "hide-details": "",
      "outlined": "",
      "items": _vm.searchKeys,
      "item-text": "text",
      "item-value": "value"
    },
    model: {
      value: _vm.filter.searchKey,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchKey", $$v);
      },
      expression: "filter.searchKey"
    }
  })], 1), _c('v-col', {
    attrs: {
      "md": "auto"
    }
  }, [_c('v-text-field', {
    staticClass: "v-input--width-fixed v-input--density-compact",
    attrs: {
      "hide-details": "",
      "outlined": "",
      "placeholder": "검색어를 입력하세요."
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.search.apply(null, arguments);
      }
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-primary', {
    on: {
      "click": _vm.search
    }
  }, [_vm._v("검색")])], 1)], 1)], 1), _c('table', {
    staticClass: "board-list"
  }, [_c('thead', {
    staticClass: "grey lighten-3 d-none d-lg-table-header"
  }, [_c('tr', [_c('th', {
    staticClass: "board-list__txt",
    staticStyle: {
      "width": "calc(1000 / 1200 * 100%)"
    }
  }, [_vm._v(_vm._s(_vm.$t("common.제목")))]), _c('th', {
    staticClass: "board-list__txt",
    staticStyle: {
      "width": "calc(200 / 1200 * 100%)"
    }
  }, [_vm._v(_vm._s(_vm.$t("common.작성일")))])])]), _c('tbody', [_vm._l(_vm.fixed, function (notification, index) {
    return _c('tr', {
      key: `fixed_${index}`,
      on: {
        "click": function ($event) {
          return _vm.$router.push(`${_vm.$route.path}/${notification._id}`);
        }
      }
    }, [_c('td', {
      staticClass: "board-list__tit",
      attrs: {
        "align": "center"
      }
    }, [_c('div', {
      staticClass: "ellip"
    }, [_c('v-icon', [_vm._v("mdi-alert-circle-outline")]), _c('span', [_vm._v(_vm._s(notification.subject))])], 1)]), _c('td', {
      staticClass: "board-list__txt board-list__txt--first",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(_vm.$dayjs(notification.createdAt).format("YYYY-MM-DD")) + " ")])]);
  }), _vm._l(_vm.notifications, function (notification, index) {
    return _c('tr', {
      key: index,
      on: {
        "click": function ($event) {
          return _vm.$router.push(`${_vm.$route.path}/${notification._id}`);
        }
      }
    }, [_c('td', {
      staticClass: "board-list__tit"
    }, [_c('div', {
      staticClass: "ellip"
    }, [_c('span', [_vm._v(_vm._s(notification.subject))])])]), _c('td', {
      staticClass: "board-list__txt board-list__txt--first",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(notification.createdAt.toDate()) + " ")])]);
  })], 2)]), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": "11"
    },
    on: {
      "input": function ($event) {
        return _vm.search(true);
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }