<template>
    <u-page-section-basic v-bind="$attrs">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </u-page-section-basic>
</template>

<script>
import UPageSectionBasic from "@/sets/styles/pages/u-page-section-basic.vue";

export default {
    props: {},
    components: {
        UPageSectionBasic,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
