var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', _vm._b({
    staticClass: "page-section"
  }, 'section', _vm.$attrs, false), [this.$slots['pageSectionHead'] ? _vm._t("pageSectionHead") : _vm._e(), this.$slots['default'] ? _c('v-container', [_vm._t("default")], 2) : _vm._e(), this.$slots['pageSectionFoot'] ? _vm._t("pageSectionFoot") : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }