var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.notification ? _c('div', {
    staticClass: "board-container"
  }, [_c('table', {
    staticClass: "board-view"
  }, [_c('tr', [_c('th', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.notification.subject))])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('common.작성일')) + " : " + _vm._s(_vm.notification.createdAt.toDate()))])])], 1)], 1)]), _vm.notification.upload ? _c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.attachments")) + " : "), _c('v-icon', [_vm._v("mdi-paperclip")]), _c('a', {
    staticStyle: {
      "text-decoration": "underline"
    },
    on: {
      "click": function ($event) {
        _vm.download(_vm.notification.upload, _vm.notification.upload.name.split('/').pop());
      }
    }
  }, [_vm._v(_vm._s(_vm.notification.upload.name.split('/').pop()))])], 1)]) : _vm._e(), _c('tr', [_c('td', {
    staticClass: "content_box",
    attrs: {
      "colspan": "2"
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.notification.content.replace(/\n/g, '<br>'))
    }
  })])])]), _c('table', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "board-view-navi"
  }, [_vm._m(0), _vm._m(1)]), _c('div', {
    staticClass: "mt-20 mt-md-30"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "v-btn--group justify-end"
  }, [_c('v-btn', {
    attrs: {
      "large": "",
      "color": "primary"
    }
  }, [_vm._v("수정")]), _c('v-btn', {
    attrs: {
      "large": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("삭제")]), _c('v-btn', {
    staticClass: "grey-2--text",
    attrs: {
      "large": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("답글")])], 1), _c('div', {
    staticClass: "v-btn--group justify-end"
  }, [_c('v-btn', {
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("common.list")))])], 1)])]) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("이전글")]), _c('td', [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("이전 게시글 제목입니다.")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("다음글")]), _c('td', [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("다음 게시글 제목입니다.")])])]);

}]

export { render, staticRenderFns }