<template>
    <page-section v-bind="$attrs">
        <tit-wrap-primary v-if="this.$slots['tit']" :class="titWrapClass" :titClass="titClass">
            <slot name="tit" />
        </tit-wrap-primary>

        <slot />
    </page-section>
</template>

<script>
import PageSection from "@/sets/styles/pages/page-section.vue";
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";

export default {
    props: {
        titWrapClass: { type: String },
        titClass: { type: String, default: "tit--lg" },
    },
    components: {
        PageSection,
        TitWrapPrimary,
    },
    data() {
        return {};
    },
    created() {},
    mounted() {
        this.init();
    },
    methods: {
        init: function () {},
    },
};
</script>

<style lang="scss" scoped></style>
